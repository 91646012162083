import React, { useEffect } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import 'animate.css';
import backgroundImage from "./assets/images/background.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import facebookImage from './assets/images/facebook.png';
import instagramImage from './assets/images/instagram.png';
import youtubeImage from './assets/images/youtube.png';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ieadam26 from './assets/images/ieadam-area-26.png';
import pastores from './assets/images/pastores.webp';
import nomePastores from './assets/images/nome-pastores.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import imagem001 from './assets/images/01.jpeg';
import imagem002 from './assets/images/02.jpeg';
import imagem003 from './assets/images/03.jpeg';
import imagem004 from './assets/images/04.jpeg';
import imagem005 from './assets/images/05.jpeg';
import imagem006 from './assets/images/06.jpeg';
import imagem007 from './assets/images/07.jpg';
import { height } from '@fortawesome/free-brands-svg-icons/fa42Group';

const App = () => {

    useEffect(() => {
        AOS.init()
    }, []);

    const contentStyle = {
        backgroundImage: `url(${backgroundImage})`
    }

    return (
        <div className='content' style={contentStyle}>
            <div className='content-container'>
                <div id="logo" className='text-center mt-3'>
                    <img src={ieadam26} alt="IEADAM Área 26" />
                </div>
                <div id='titulo' className='animate__animated animate__fadeIn pt-5 mb-4'>
                    Quem Somos
                </div>
                <div id="texto-quem-somos" className='animate__animated animate__fadeIn'>
                    <p>Nós somos a <b>Igreja Evangélica Assembleia de Deus no Amazonas - IEADAM</b>, no bairro <b>Novo Aleixo</b>. Nosso templo sede está localizado na <b>Rua Ouricana, n° 90.</b></p>

                    <p>Realizamos efetivamente reuniões que atendem ao <b>público infantil, jovem e adulto</b>. Temos encontros semanais para crianças, jovens, mulheres e homens; aconselhamento familiar, financeiro; palestras com psicóloga, advogado e outros.</p>

                    <p>Nossos pastores são <b>Wictor e Adeniza São Paulo</b> e teremos imenso prazer de receber a sua visita.</p>
                </div>

                <div id="pastores" class="mt-5 pt-4">
                    <div className="text-left" id="esquerda" data-aos="fade-right" data-aos-duration="2000">
                        <img src={nomePastores} alt="Pastores Wictor e Adeniza São Paulo" id="nome-pastores" />
                    </div>

                    <div className='text-right' id='direita' data-aos="fade-left" data-aos-duration="2000">
                        <img src={pastores} alt="Pastores Wictor e Adeniza São Paulo" id="imagem-pastores" />
                    </div>
                </div>

                <div id='titulo' className='animate__animated animate__fadeIn mt-5 pt-5 mb-4'>
                    Nossos Cultos
                </div>

                <div id="texto-nossos-cultos" className='animate__animated animate__fadeIn'>
                    <div data-aos="fade-up" data-aos-delay="500">
                        <p className='fw-bold'>Domingo</p>
                        <ul className="fa-ul">
                            <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span> Escola Bíblica Dominical, às 8hrs</li>
                            <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span> Culto de Celebração, às 18hrs</li>
                        </ul>
                    </div>

                    <div data-aos="fade-up" data-aos-delay="500">
                        <p className='fw-bold'>Terça-feira</p>
                        <ul className="fa-ul">
                            <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span> Culto de Oração e Ensino, às 19hrs</li>
                        </ul>
                    </div>

                    <div data-aos="fade-up" data-aos-delay="500">
                        <p className='fw-bold'>Quarta-feira</p>
                        <ul className="fa-ul">
                            <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span> Rede de Mulheres, às 19hrs</li>
                        </ul>
                    </div>

                    <div data-aos="fade-up" data-aos-delay="500">
                        <p className='fw-bold'>Sexta-feira</p>
                        <ul className="fa-ul">
                            <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span> Rede de Homens, às 19hrs</li>
                        </ul>
                    </div>

                    <div data-aos="fade-up" data-aos-delay="500">
                        <p className='fw-bold'>Sábado</p>
                        <ul className="fa-ul">
                            <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span> Rede de Crianças, às 15hrs</li>
                            <li><span className="fa-li"><FontAwesomeIcon icon={faArrowRight} /></span> Rede de Jovens, às 19hrs</li>
                        </ul>
                    </div>
                </div>

                <div id="carrossel" className='mt-5 pt-3' data-aos="flip-up" data-aos-delay="500">
                    <Carousel
                        infiniteLoop={true}
                        labels={{
                            leftArrow: 'Anterior',
                            rightArrow: 'Próximo',
                            item: 'Imagem'
                        }}
                        statusFormatter={(current, total) => `${current} de ${total}`}
                        showThumbs={false}
                        autoPlay={true}
                        emulateTouch={true}
                    >
                        <div className='foto-carrosel' style={{ backgroundImage: `url(${imagem001})` }}></div>
                        <div className='foto-carrosel' style={{ backgroundImage: `url(${imagem002})` }}></div>
                        <div className='foto-carrosel' style={{ backgroundImage: `url(${imagem003})` }}></div>
                        <div className='foto-carrosel' style={{ backgroundImage: `url(${imagem004})` }}></div>
                        <div className='foto-carrosel' style={{ backgroundImage: `url(${imagem005})` }}></div>
                        <div className='foto-carrosel' style={{ backgroundImage: `url(${imagem006})` }}></div>
                        <div className='foto-carrosel' style={{ backgroundImage: `url(${imagem007})` }}></div>
                    </Carousel>
                </div>

                <div data-aos="zoom-in" data-aos-delay="500" className='mt-1 pt-3 pb-3'>
                    <div id='titulo' className="mb-4">
                        Nossa Localização
                    </div>
                    <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15936.76675959462!2d-59.9540391!3d-3.0432408!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x926c1b70472cffd7%3A0xfb64a1cdf4fbae43!2sAssembleia%20de%20Deus%20Mutirao%20-%20%C3%81rea%2026!5e0!3m2!1spt-BR!2sbr!4v1714998730490!5m2!1spt-BR!2sbr"
                            height="450"
                            style={{ border: 0, width: '100%' }}
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>


                <div id='titulo' className='animate__animated animate__fadeIn mt-5 pt-5 mb-4'>
                    Pedidos de Oração
                </div>
                <div>
                    Nos chame no Whatsapp e teremos o prazer de orar por você! Qualquer outra dúvida, nos chame também.
                </div>
                <div className='text-center mt-4 pb-5'>
                    <a href="https://wa.me/5592981378961" className='btn btn-success' style={{ width: '200px', textAlign: 'center' }} target='_blank'>
                        <FontAwesomeIcon icon={faWhatsapp} /> Clique Aqui
                    </a>
                </div>

                <div id='titulo' className='animate__animated animate__fadeIn mt-5 pt-5 mb-4'>
                    Nos siga nas Redes Sociais
                </div>
                <div className='text-center'>
                    <a href="https://www.facebook.com/admutirao26" target='_blank'>
                        <img src={facebookImage} alt="Facebook" />
                    </a>
                    <a href="https://www.instagram.com/admutirao26" target='_blank'>
                        <img src={instagramImage} alt="Instagram" />
                    </a>
                    <a href="https://www.youtube.com/@admutirao26" target='_blank'>
                        <img src={youtubeImage} alt="Youtube" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default App